import React from "react";
import {
  BannerBox,
  ButtonBanner,
  BannerSpan,
  HalfBanner,
  TextBanner,
  TextButtonBanner,
  TitleBanner,
  BlockTextBanner,
  PatternBanner,
  Half2Banner,
  BannerContainer,
  FirstCoin,
  SecondCoin,
} from "./BannerStyled";
import Coin1 from "../../assets/coinLeft.png";
import Coin2 from "../../assets/coinRight.png";
import { Trans } from "@lingui/macro";
import { cronusLink } from "../../utils/link";
const Banner = () => {
  return (
    <BannerContainer>
      <FirstCoin src={Coin1} style={{ minWidth: "50px" }} alt="coin1" />
      <SecondCoin src={Coin2} style={{ minWidth: "50px" }} alt="coin2" />

      <BannerBox>
        <HalfBanner>
          <PatternBanner />
        </HalfBanner>
        <Half2Banner>
          <BlockTextBanner>
            <TitleBanner>
              <Trans>
                Gain an edge on the market
                <BannerSpan> with Cronus </BannerSpan>
              </Trans>
            </TitleBanner>
          </BlockTextBanner>
          <a target="_blank" rel="noopener" href={cronusLink.launch}>
            <ButtonBanner>
              <TextButtonBanner>Launch App</TextButtonBanner>
            </ButtonBanner>
          </a>
        </Half2Banner>
      </BannerBox>
    </BannerContainer>
  );
};

export default Banner;

import React from "react";
import {
  CardContainer,
  LeftCard,
  RightCard,
  TextAndButtonContainer,
  TextContainer,
  TitleContainer,
  LaunchPadIcon,
  ZapIcon,
  LendingIcon,
  VaultsIcon,
  CardHiddenContainer,
  ContainerBoxTrans,
  IconBoxHidden,
  TitleHidden,
  FirstPaHidden,
  SecondPaHidden,
  ThirdPaHidden,
  PatternHidden,
  PatternRight,
  BridgeIcon,
  AnalyticsIcon,
  StakeIcon,
} from "./CardStyled";
import { CardComing } from "./interfaces";

const Card = ({ icon, textBox, title }: CardComing) => {
  const getIcon = () => {
    switch (icon) {
      case "launchpad":
        return <LaunchPadIcon></LaunchPadIcon>;
      case "zap":
        return <ZapIcon></ZapIcon>;
      case "lending":
        return <LendingIcon></LendingIcon>;
      case "vaults":
        return <VaultsIcon></VaultsIcon>;
      case "bridge":
        return <BridgeIcon></BridgeIcon>;
      case "analytics":
        return <AnalyticsIcon />;
      case "stake":
        return <StakeIcon />;
    }
  };
  return (
    <CardContainer>
      <TextAndButtonContainer>
        <LeftCard>
          {getIcon()}
          <TitleContainer>{title}</TitleContainer>
          <TextContainer>{textBox}</TextContainer>
        </LeftCard>
        <RightCard>
          <PatternRight />
        </RightCard>
      </TextAndButtonContainer>
    </CardContainer>
  );
};

const Card2 = () => {
  return (
    <CardHiddenContainer>
      <ContainerBoxTrans>
        <IconBoxHidden />
        <TitleHidden />
        <FirstPaHidden />
        <SecondPaHidden />
        <ThirdPaHidden />
        <PatternHidden />
      </ContainerBoxTrans>
    </CardHiddenContainer>
  );
};

export { Card, Card2 };

import React from "react";
import {
  BoxCard,
  SpanComingsoon,
  TitleComingsoon,
  ComingSoonContainer,
  LeftCoinImage,
} from "./ComingsoonStyled";
import CoinsLeft from "../../assets/coinsleft.png";

import { Card, Card2 } from "./card";
import { Trans } from "@lingui/macro";

const Comingsoon = () => {
  return (
    <>
      <ComingSoonContainer>
        <LeftCoinImage src={CoinsLeft} alt="coin" />
        <TitleComingsoon>
          <Trans>
            Coming <SpanComingsoon>soon...</SpanComingsoon>
          </Trans>
        </TitleComingsoon>
        {/* <BoxCard>
         
          <Card
            icon={"zap"}
            title={"Zap"}
            textBox={"Convert whitelisted tokens to LP tokens quickly. "}
          />
          <Card
            icon={"lending"}
            title={"Lending"}
            textBox={
              "Mauris elementum elit ut est pretium, consectetur convallis ex elementum."
            }
          />
          <Card
            icon={"vaults"}
            title={"Vaults"}
            textBox={
              "Mauris elementum elit ut est pretium, consectetur convallis ex elementum."
               
            }
          />*/}
        <BoxCard>
          <Card
            icon={"stake"}
            title={"Staking"}
            textBox={"Earn from fees and liquidations by staking your CRN!"}
          />
          <Card
            icon={"bridge"}
            title={"Copytrading"}
            textBox={"Start earning by copying top trader strategies"}
          />
          <Card
            icon={"analytics"}
            title={"Exotic Collaterals"}
            textBox={
              "Trade tokens and assets never before seen in the perpetual trading world"
            }
          />
          <Card2 />
        </BoxCard>
      </ComingSoonContainer>
    </>
  );
};

export default Comingsoon;

import styled, { keyframes } from "styled-components";
import { ReactComponent as Pattern } from "../../../assets/patterncard.svg";
import { ReactComponent as Swap } from "../../../assets/icons/swap.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-right.svg";
import { default as Pattern2 } from "../../../assets/patterncard.svg";
import { ReactComponent as Zap } from "../../../assets/icons/zap.svg";
import { ReactComponent as Vaults } from "../../../assets/icons/vaults.svg";
import { ReactComponent as Lending } from "../../../assets/icons/lending.svg";
import { ReactComponent as Launchpad } from "../../../assets/icons/launchpad.svg";
import { ReactComponent as Bridge } from "../../../assets/icons/bridge.svg";
import { ReactComponent as Stake } from "../../../assets/icons/stake.svg";
import { ReactComponent as Analytics } from "../../../assets/icons/analytics.svg";

const CardContainer = styled.div`
  width: 300px;
  max-height: 300px;
  background: #191b1f;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
`;

const TitleContainer = styled.p`
  height: 42px;
  width: 227px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 175%;
  color: white;
  opacity: 0.6;
`;

const TextContainer = styled.p`
  width: 227px;
  height: 75px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  color: white;
  opacity: 0.6;
`;

const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const RightCard = styled.div`
  display: flex;
  height: 250px;
  width: 30%;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
const SwapIcon = styled(Swap)`
  width: 45px;
  height: 45px;
`;
const StakeIcon = styled(Stake)`
  width: 52px;
  height: 45px;

  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const AnalyticsIcon = styled(Analytics)`
  fill: #bbbbbb !important;
  width: 52px;
  height: 45px;

  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const BridgeIcon = styled(Bridge)`
  fill: #bbbbbb !important;
  width: 52px;
  height: 52px;

  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;

const LaunchPadIcon = styled(Launchpad)`
  width: 45px;
  height: 45px;
  fill: white; ;
`;

const VaultsIcon = styled(Vaults)`
  width: 52px;
  height: 48px;
`;
const ZapIcon = styled(Zap)`
  width: 30px;
  height: 52.5px;
`;
const LendingIcon = styled(Lending)`
  width: 60px;
  height: 52.5px;
`;
const PatternRight = styled(Pattern)`
  height: 300px;

  position: absolute;
  left: 11.75rem;

  z-index: 1;
`;

const ButtonCard = styled.div`
  width: 57px;
  height: 57px;
  background: #b50028;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const ArrowRightIcon = styled(Arrow)`
  width: 21px;
  height: 24px;
`;

// NEW CARDS -------- *******************

const CardHiddenContainer = styled.div`
  width: 300px;
  height: 300px;
  background: #191b1f;
  border-radius: 0px;
  position: relative;
  padding: 2rem;
  @media (max-width: 920px) {
    display: none;
  }
`;
const Shimmer = keyframes`
0% {
  background-position: 0px 0;
  }
100% {
  background-position: 600px 0;
}`;
const ContainerBoxTrans = styled.div`
  width: 100%;
  position: relative;
`;

const WhiteBox = styled.div`
  background: #fff;
  position: absolute;
`;

const IconBoxHidden = styled.div`
  width: 60px;
  height: 60px;
  background: #edeae9;
  border-radius: 100px;
  position: absolute;
  left: 0rem;
  background-image: linear-gradient(
    to right,
    #191b1f 0%,
    #2c2f36 20%,
    #191b1f 100%
  );
  background-color: #191b1f;
  animation: 6s ${Shimmer} infinite linear;
`;

const TitleHidden = styled.div`
  position: absolute;
  width: 143px;
  height: 30px;
  background: #edeae9;
  left: 0rem;
  bottom: -7.75rem;
  background-image: linear-gradient(
    to right,
    #191b1f 0%,
    #2c2f36 20%,
    #191b1f 100%
  );
  background-color: #191b1f;
  animation: 6s ${Shimmer} infinite linear;
`;

const FirstPaHidden = styled.div`
  width: 204px;
  height: 16px;
  position: absolute;
  left: 0rem;
  background: #edeae9;
  bottom: -11.5rem;
  background-image: linear-gradient(
    to right,
    #191b1f 0%,
    #2c2f36 20%,
    #191b1f 100%
  );
  background-color: #191b1f;
  animation: 6s ${Shimmer} infinite linear;
`;
const SecondPaHidden = styled.div`
  width: 212px;
  height: 16px;
  position: absolute;
  left: 0rem;
  background: #edeae9;
  bottom: -13rem;
  background-image: linear-gradient(
    to right,
    #191b1f 0%,
    #2c2f36 20%,
    #191b1f 100%
  );
  background-color: #191b1f;
  animation: 6s ${Shimmer} infinite linear;
`;
const ThirdPaHidden = styled.div`
  z-index: 5;
  width: 107px;
  height: 16px;
  position: absolute;
  left: 0rem;
  background: #edeae9;
  bottom: -14.5rem;
  background-image: linear-gradient(
    to right,
    #191b1f 0%,
    #2c2f36 20%,
    #191b1f 100%
  );
  background-color: #191b1f;
  animation: 6s ${Shimmer} infinite linear;
`;

const PatternHidden = () => {
  return (
    <img
      src={Pattern2}
      alt="pattern"
      style={{
        zIndex: 1,
        height: "300px",
        position: "absolute",
        left: "10rem",
        top: "-2rem",
      }}
    />
  );
};

export {
  CardContainer,
  TitleContainer,
  TextContainer,
  SwapIcon,
  PatternRight,
  ButtonCard,
  ArrowRightIcon,
  TextAndButtonContainer,
  LeftCard,
  RightCard,
  ZapIcon,
  VaultsIcon,
  LendingIcon,
  LaunchPadIcon,
  CardHiddenContainer,
  ContainerBoxTrans,
  WhiteBox,
  IconBoxHidden,
  TitleHidden,
  FirstPaHidden,
  SecondPaHidden,
  ThirdPaHidden,
  PatternHidden,
  StakeIcon,
  BridgeIcon,
  AnalyticsIcon,
};

import React from "react";
import {
  ButtonLaunch,
  HeaderContainer,
  ButtonTextHeader,
  Menu,
  SpaceSection,
  TitleHeader,
  BridgeIcon,
  SwapIcon,
  LiquidityIcon,
  FarmsIcon,
  MenuHamburger,
  HeaderContainerMobile,
} from "./HeaderStyled";
import { Logo } from "../general/logo";
import { cronusLink } from "../../utils/link";
import { Trans } from "@lingui/macro";

const HeaderMenu = () => {
  return (
    <>
      <HeaderContainer>
        <Logo />
        <Menu>
          <SpaceSection>
            <LiquidityIcon />
            <TitleHeader target="_blank" href={cronusLink.launch}>
              <Trans>Perpetuals</Trans>
            </TitleHeader>
          </SpaceSection>
          <SpaceSection>
            <BridgeIcon />
            <TitleHeader target="_blank">
              <Trans>
                OP BRIDGE{" "}
                <span style={{ fontSize: "11px" }}>(coming soon)</span>
              </Trans>
            </TitleHeader>
          </SpaceSection>
          <SpaceSection>
            <SwapIcon />
            <TitleHeader target="_blank" href={cronusLink.swap}>
              <Trans>EVMOS DEX</Trans>
            </TitleHeader>
          </SpaceSection>
          <ButtonLaunch target="_blank" href={cronusLink.launch}>
            <ButtonTextHeader>
              <Trans>LAUNCH APP</Trans>
            </ButtonTextHeader>
          </ButtonLaunch>
        </Menu>
      </HeaderContainer>
      <HeaderContainerMobile>
        <Logo />
        <MenuHamburger />
      </HeaderContainerMobile>
    </>
  );
};

export default HeaderMenu;

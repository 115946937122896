import React from "react";
import {
  SpaceDescription,
  SingleDescription,
  TitleDescription,
  LogoDescription,
  TextDescription,
  ShuttleIcon,
  CircleIcon,
  LockIcon,
  BoltIcon,
} from "./DescriptionStyled";

import Bolt from "../../assets/bolt.png";
import { Trans } from "@lingui/macro";
const Description = () => {
  return (
    <SpaceDescription className="description">
      <SingleDescription>
        <LogoDescription>
          <ShuttleIcon />
        </LogoDescription>

        <TitleDescription>
          <Trans>DYNAMIC</Trans>
        </TitleDescription>

        <TextDescription>
          <Trans>
            Focused on growth and user feedback, Cronus will aim to become the
            best retail user experience for Perpetuals on <b>Optimism</b>,
            helping the chain grassroots DeFi movement
          </Trans>
        </TextDescription>
      </SingleDescription>
      <SingleDescription>
        <LogoDescription>
          <CircleIcon />
        </LogoDescription>
        <TitleDescription>
          <Trans>INNOVATIVE</Trans>
        </TitleDescription>

        <TextDescription>
          <Trans>
            With an aim towards new solutions and real yield, we focus on
            delivering sustainable yield and an hybrid design by combining the
            best of DeFi and social trading to create new innovative solutions
          </Trans>
        </TextDescription>
      </SingleDescription>
      <SingleDescription>
        <LogoDescription>
          <LockIcon />
        </LogoDescription>
        <TitleDescription>
          <Trans>USER-FRIENDLY</Trans>
        </TitleDescription>

        <TextDescription>
          <Trans>
            Giving users a top notch customizable UI/UX experience is a key
            component of the Cronus project, we aim to leverage Optimism
            features and future updates to enhance the protocol usability and
            efficiency
          </Trans>
        </TextDescription>
      </SingleDescription>

      <BoltIcon style={{ minWidth: "50px" }} src={Bolt} />
    </SpaceDescription>
  );
};

export default Description;

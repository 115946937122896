import { Home } from "./pages/home";
import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

const theme = {
  main: "top",
};
const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden!important;

      background-color:#2c2f36;
      max-width: 1280px;
      margin: 0 auto 0 auto;

      @media screen and (max-width:768px){
        margin: 1.5rem;
      }
      @media screen and (max-width: 1250px){
        margin : 0 2rem 2rem 2rem;
    }
      
    }
    
   *{
     box-sizing: border-box;
     
   }
   
   a{
     text-decoration:none;
   }

   @media(max-width: 500px){
     max-width:450px;
   }

   @media(max-width: 768px){
    max-width:730px;
  }

  @media(max-width: 960px){
    max-width: 800 px;
  }


  
  
`;

i18n.activate("en");

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nProvider i18n={i18n}>
        <GlobalStyle />
        <Home />
      </I18nProvider>
    </ThemeProvider>
  );
}

export default App;

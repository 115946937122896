import styled from "styled-components";

const FirstSectionHome = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LastSectionHome = styled.div`
  display: flex;
  flex-direction: row;
`;
const VideoLoop = styled.video`
  max-width: 700px;
  z-index: -1;
  @media (max-width: 920px) {
    position: static;
    width: 100%;
    max-height: 350px;
  }
`;

const FallbackImage = styled.img`
  max-width: 700px;
  z-index: -1;
  @media (max-width: 920px) {
    position: static;
    width: 100%;
    max-height: 350px;
  }
`;

export { FirstSectionHome, LastSectionHome, VideoLoop, FallbackImage };

import styled from "styled-components";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Discord } from "../../assets/discord.svg";
import { ReactComponent as Medium } from "../../assets/medium.svg";
import { Logo } from "../general/logo";
import { socialLink } from "../../utils/link";

const BoxFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;
const LeftFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftFooterMobile = styled.div`
  display: none;
  @media (max-width: 765px) {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`;
const ParagraphFooter = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 50%;
    align-content: center;
    align-items: center;
  }
`;

const FooterMobile = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const TitleParagraph = styled.p`
  width: 190px;
  height: 25px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #bbbbbb;
  @media (max-width: 768px) {
    width: 165px;
    height: 21px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #bbbbbb;
  }
`;

const TextParagraph = styled.a`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: white;
  text-decoration: none;
  line-height: 40px;
  @media (max-width: 768px) {
    width: 165px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 250%;
    /* or 35px */
    /* white */
    color: white;
  }
`;

const RightFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TwitterIcon = styled(Twitter)`
  width: 24px;
  height: 28px;
  opacity: 0.5;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 23px;
  }
`;

const MediumIcon = styled(Medium)`
  width: 24px;
  height: 28px;
  opacity: 0.5;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 23px;
  }
`;
const DiscordIcon = styled(Discord)`
  width: 30px;
  height: 28px;
  opacity: 0.5;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: 25px;
    height: 23px;
  }
`;

const IconSection = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const FooterStyle = styled.div`
  @media (max-width: 920px) {
    display: none;
  }
`;

const FooterStyleMobile = styled.div`
  display: none;
  @media (max-width: 920px) {
    width: 100%;
    max-width: 100%;
    display: block;
  }
`;

const SectionFooterMobile = styled.div`
  @media (max-width: 920px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContainerFooter = styled.div`
  @media (max-width: 768px) {
    display: flex;
    max-width: 100%;
    alignitems: center;
    aligncontent: center;
    justifycontent: center;
  }
`;

const LogoMobile = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
      <Logo />
      <IconBlock />
    </div>
  );
};
const IconBlock = () => {
  return (
    <IconSection>
      <a target="_blank" rel="noopener noreferrer" href={socialLink.twitter}>
        <TwitterIcon title="Twitter | Cronus Finance" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={socialLink.discord}>
        <DiscordIcon title="Discord | Cronus Finance" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={socialLink.medium}>
        <MediumIcon title="Medium | Cronus Finance" />
      </a>
    </IconSection>
  );
};

export {
  BoxFooter,
  TitleParagraph,
  ParagraphFooter,
  RightFooter,
  TextParagraph,
  IconBlock,
  LeftFooter,
  FooterMobile,
  LeftFooterMobile,
  FooterStyle,
  FooterStyleMobile,
  LogoMobile,
  SectionFooterMobile,
  ContainerFooter,
};

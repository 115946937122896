import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";

const TitlePresentation = styled.p`
  max-width: 630px;
  max-height: 208px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #b50028;
  @media (max-width: 920px) {
    width: 100%;
    max-height: 180px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    color: #b50028;
  }
`;
const TextPresentation = styled.p`
  max-width: 520px;
  height: 140px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 175%;
  color: white;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    font-size: 14px;
  }
`;
const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SpanPresentation = styled.span`
  background: linear-gradient(
    to bottom,
    #2c2f36 0%,
    #2c2f36 50%,
    #191b1f 50%,
    #191b1f 100%
  );
  color: white;
`;

const DiscoverMoreSection = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

const ButtonLaunch = styled.a`
  cursor: pointer;
  background-color: #b50028;
  max-width: 172px;
  height: 50px;
  border: none;
  padding: 15px 30px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  align-content: center;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover,
  &:focus {
    background-color: #a20024;
  }
`;

const ArrowIcon = styled(ArrowDown)`
  fill: #bbbbbb !important;
  * {
    fill: #bbbbbb !important;
  }
  width: 22px;
  height: 22px;
  margin-left: 10px;
  @media (max-width: 920px) {
    display: none;
  }
`;

const DiscoverContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const TextDiscoverMore = styled.div`
  margin-left: 20px;
  height: 28px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  opacity: 0.5;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  ${DiscoverContainer}:hover & {
    opacity: 0.8;
  }

  @media (max-width: 920px) {
    display: none;
  }
`;

const ButtonTextPresentation = styled.p`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
`;

const PresentationTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export {
  TitlePresentation,
  PresentationTopContainer,
  TextPresentation,
  PresentationContainer,
  SpanPresentation,
  DiscoverMoreSection,
  TextDiscoverMore,
  ArrowIcon,
  ButtonLaunch,
  ButtonTextPresentation,
  DiscoverContainer,
};

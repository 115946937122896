import styled from "styled-components";
import { ReactComponent as Shuttle } from "../../assets/icons/shuttle.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { ReactComponent as Circle } from "../../assets/icons/circle.svg";

const TitleDescription = styled.p`
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 175%;
  color: #bbbbbb;
  @media (max-width: 920px) {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 175%;
    color: #bbbbbb;
  }
`;

const CircleIcon = styled(Circle)`
  width: 32px;
  height: 32px;
  @media (max-width: 920px) {
    width: 24px;
    height: 24px;
  }
`;

const ShuttleIcon = styled(Shuttle)`
  width: 32px;
  height: 32px;
  @media (max-width: 920px) {
    width: 24px;
    height: 24px;
  }
`;

const LockIcon = styled(Lock)`
  width: 28px;
  height: 32px;
  @media (max-width: 920px) {
    width: 21px;
    height: 24px;
  }
`;

const LogoDescription = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  /* #b50028 */

  background: #b50028;
  border-radius: 0px;

  @media (max-width: 920px) {
    width: 60px;
    height: 60px;
    background: #b50028;
    border-radius: 0px;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const TextDescription = styled.p`
  width: 100%;
  height: 160px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  color: white;
  margin: 0px;
  @media (max-width: 920px) {
    height: 100px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    color: white;
  }
`;
const SpaceDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  place-content: space-between;
  margin-bottom: 15rem;
  @media (max-width: 920px) {
    flex-direction: column;
    margin-bottom: 0rem;
  }
`;

const SingleDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.75rem;
  flex: 0 1 30%;
  @media (max-width: 920px) {
    margin: 2rem 0rem;
    flex: unset;
  }
`;

const BoltIcon = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    max-width: 411px;
  }
  @media (max-width: 920px) {
    display: block;
    max-width: 700px;
  }
`;

export {
  TitleDescription,
  TextDescription,
  LogoDescription,
  SpaceDescription,
  SingleDescription,
  ShuttleIcon,
  CircleIcon,
  LockIcon,
  BoltIcon,
};

import { Trans } from "@lingui/macro";
import React from "react";
import { cronusLink, goto } from "../../utils/link";
import {
  PresentationContainer,
  TextPresentation,
  TitlePresentation,
  SpanPresentation,
  DiscoverMoreSection,
  ArrowIcon,
  TextDiscoverMore,
  ButtonLaunch,
  ButtonTextPresentation,
  PresentationTopContainer,
  DiscoverContainer,
} from "./PresentationStyled";

const Presentation = () => {
  const goDown = () => {
    document.querySelector(".description")?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <PresentationTopContainer>
      <PresentationContainer>
        <TitlePresentation>
          <Trans>
            The edge of Defi,{" "}
            <SpanPresentation> in your hands</SpanPresentation>
          </Trans>
        </TitlePresentation>
        <TextPresentation>
          <Trans>
            The grassroots perpetual dex merging the best of DeFi to achieve the
            ultimate user experience
          </Trans>
        </TextPresentation>
      </PresentationContainer>
      <DiscoverMoreSection>
        <ButtonLaunch target="_blank" href={cronusLink.launch}>
          <ButtonTextPresentation>
            <Trans>LAUNCH APP</Trans>
          </ButtonTextPresentation>
        </ButtonLaunch>
        <DiscoverContainer onClick={() => goDown()}>
          <TextDiscoverMore>
            <Trans>DISCOVER MORE</Trans>
          </TextDiscoverMore>
          <ArrowIcon />
        </DiscoverContainer>
      </DiscoverMoreSection>
    </PresentationTopContainer>
  );
};

export default Presentation;

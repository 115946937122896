import styled from "styled-components";
import { ReactComponent as Pattern } from "../../../assets/patterncard.svg";
import { ReactComponent as Swap } from "../../../assets/icons/swap.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as Liquidity } from "../../../assets/icons/liquidity.svg";
import { ReactComponent as Farm } from "../../../assets/icons/farms.svg";
import { ReactComponent as Stake } from "../../../assets/icons/stake.svg";
import { ReactComponent as Analytics } from "../../../assets/icons/analytics.svg";
import { ReactComponent as Bridge } from "../../../assets/icons/bridge.svg";

const CardContainer = styled.div`
  cursor: pointer;
  max-width: 400px;
  height: 320px;
  position: relative;
  background: #191b1f;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 2rem;

  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 200ms;

  &:hover {
    background: #b50028;
    transform: translateY(-0.75rem);
  }

  @media (max-width: 920px) {
    max-width: 330px;
    &:hover {
      background: #ffffff;
      transform: unset;
    }
    max-width: 350px;
    height: 220px;
    border-radius: 0px;
    margin-bottom: 50px;
  }
`;
const TitleContainer = styled.p`
  height: 53px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 175%;
  color: #b50028;
  ${CardContainer}:hover & {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    width: 46px;
    height: 20px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #b50028;
    ${CardContainer}:hover & {
      color: #b50028;
    }
  }
  @media screen and (max-width: 920px) {
    font-size: 22px;
    ${CardContainer}:hover & {
      color: #b50028;
    }
  }
`;
const TextContainer = styled.p`
  max-width: 227px;
  height: 75px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  color: white;
  ${CardContainer}:hover & {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    max-width: 189px;
    height: 48px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: white;
    ${CardContainer}:hover & {
      color: white;
    }
  }
`;
const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;
const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  padding-right: 0.5rem;
  align-items: center;
  align-content: center;
`;
const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
const SwapIcon = styled(Swap)`
  width: 45px;
  height: 45px;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 19px;
    height: 19px;
    ${CardContainer}:hover & {
      fill: unset !important;
    }
  }
`;
const LiquidityIcon = styled(Liquidity)`
  width: 52px;
  height: 52px;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const FarmIcon = styled(Farm)`
  width: 26px;
  height: 54px;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const StakeIcon = styled(Stake)`
  width: 52px;
  height: 45px;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const AnalyticsIcon = styled(Analytics)`
  width: 52px;
  height: 45px;
  fill: #bbbbbb;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const BridgeIcon = styled(Bridge)`
  width: 52px;
  height: 52px;
  ${CardContainer}:hover & {
    fill: #ffffff !important;
  }
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;
const PatternRight = styled(Pattern)`
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
`;
const ButtonCard = styled.div`
  width: 57px;
  height: 57px;
  background: #b50028;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    background: #b50028;
    border-radius: 0px;
  }
`;

const ArrowRightIcon = styled(Arrow)`
  width: 21px;
  height: 24px;
  @media (max-width: 768px) {
    width: 14px;
    height: 16px;
  }
`;

export {
  CardContainer,
  TitleContainer,
  TextContainer,
  SwapIcon,
  PatternRight,
  ButtonCard,
  ArrowRightIcon,
  TextAndButtonContainer,
  LeftCard,
  RightCard,
  LiquidityIcon,
  FarmIcon,
  StakeIcon,
  AnalyticsIcon,
  BridgeIcon,
};

import styled from "styled-components";
import { ReactComponent as Bridge } from "../../assets/icons/bridge.svg";
import { ReactComponent as Stake } from "../../assets/icons/stake.svg";
import { ReactComponent as Liquidity } from "../../assets/icons/liquidity.svg";
import { ReactComponent as Farms } from "../../assets/icons/farms.svg";
import { ReactComponent as Swap } from "../../assets/icons/swap.svg";
import { slide as MenuSlide } from "react-burger-menu";
import { cronusLink } from "../../utils/link";
import { useEffect, useState } from "react";

const HeaderContainerMobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    padding-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }
`;
const ButtonLaunch = styled.a`
  cursor: pointer;

  background-color: #b50028;
  max-width: 172px;
  height: 50px;
  border: none;
  padding: 15px 30px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  align-content: center;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover,
  &:focus {
    background-color: #a20024;
  }

  @media (max-width: 920px) {
    max-width: 100px;
  }
`;
const TitleHeader = styled.a`
  height: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: white;
  opacity: 0.5;
  text-decoration: none;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;

  &:hover {
    opacity: 0.8;
  }
`;
const ButtonTextHeader = styled.span`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px 0px;
  text-decoration: none;
`;
const Menu = styled.div`
  gap: 2rem;
  background-color: #191b1f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 0px;
  padding: 1.75rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ContainerHamburgerMenu = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const MenuHamburger = () => {
  const [menuState, setMenuState] = useState(false);
  let styles = {
    bmBurgerButton: {
      position: "relative",
      width: "25px",
      height: "22px",
    },
    bmBurgerBars: {
      background: "white",
      height: "3px",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      top: "0",
    },
    bmMenu: {
      overflow: "hidden",
      background: "#373a47",
      fontSize: "1.15em",
      padding: "2rem 0 0 0 ",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
      display: "flex",
      flexDirection: "column",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      top: "0px",
      left: "0px",
      overflowY: "hidden",
    },
  };

  const LinkMenu = styled.a`
    text-decoration: none;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 300%;
    padding: 0 0.5rem;
  `;

  useEffect(() => {
    if (menuState === true) {
      console.log("ok");
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuState]);
  const handleStateChange = (state: boolean) => {
    setMenuState(state);
  };

  return (
    <ContainerHamburgerMenu>
      <MenuSlide
        onStateChange={(e) => handleStateChange(e.isOpen)}
        isOpen={menuState}
        styles={styles}
        right
      >
        <LinkMenu href={cronusLink.launch}>
          <LiquidityIcon fill={"#ffffff"} />
          Perpetuals
        </LinkMenu>
        <LinkMenu>
          <BridgeIcon fill={"#ffffff"} />
          OP Bridge <span style={{ fontSize: "11px" }}>(Coming soon)</span>
        </LinkMenu>
        <LinkMenu href={cronusLink.swap}>
          <SwapIcon fill={"#ffffff"} />
          Evmos DEX
        </LinkMenu>
      </MenuSlide>
    </ContainerHamburgerMenu>
  );
};

const SpaceSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const BridgeIcon = styled(Bridge)`
  fill: #bbbbbb !important;
  margin-right: 10px;
  height: 14px;
  width: 14px;
`;

const StakeIcon = styled(Stake)`
  margin-right: 10px;
  width: 14px;
  height: 12px;
`;

const FarmsIcon = styled(Farms)`
  margin-right: 10px;
  width: 7px;
  height: 14.5px;
`;

const LiquidityIcon = styled(Liquidity)`
  margin-right: 10px;
  width: 14px;
  height: 14px;
`;

const SwapIcon = styled(Swap)`
  margin-right: 10px;
  width: 12px;
  height: 12px;
`;
export {
  ButtonLaunch,
  Menu,
  TitleHeader,
  ButtonTextHeader,
  SpaceSection,
  HeaderContainer,
  BridgeIcon,
  SwapIcon,
  LiquidityIcon,
  FarmsIcon,
  StakeIcon,
  MenuHamburger,
  HeaderContainerMobile,
};

import { Trans } from "@lingui/macro";
import React from "react";
import {
  BoxOurPartners,
  TitleOurPartners,
  SpanOurPartners,
  CarouselPartners,
  AuditedPartner,
  BackedBy,
  BackedByMobile,
  BoxOurPartnersMobile,
  CarouselParnersMobile,
  DivContainerAudited,
} from "./OurPartnersStyled";

const OurPartners = () => {
  return (
    <>
      <BoxOurPartners>
        <TitleOurPartners>
          <Trans>
            Backed <SpanOurPartners>by</SpanOurPartners>
          </Trans>
        </TitleOurPartners>
        <BackedBy />
      </BoxOurPartners>
      <BoxOurPartnersMobile>
        <TitleOurPartners>
          <Trans>
            Backed <SpanOurPartners>by</SpanOurPartners>
          </Trans>
        </TitleOurPartners>
        <BackedByMobile />
      </BoxOurPartnersMobile>
    </>
  );
};

export default OurPartners;

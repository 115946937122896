import styled from "styled-components";
const TitleComingsoon = styled.p`
  width: 630px;
  height: 34px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 130%;
  color: #b50028;
`;
const SpanComingsoon = styled.span`
  color: white;
`;

const LeftCoinImage = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 300px;
  position: absolute;
  left: -20rem;
  top: 6rem;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const BoxCard = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const ComingSoonContainer = styled.div`
  position: relative;
  margin-bottom: 10rem;
  @media (max-width: 920px) {
    display: none;
    margin-bottom: 0rem;
  }
`;

const LeftCoinStyled = styled.img`
  position: absolute;
  left: 50%;
`;
export {
  TitleComingsoon,
  SpanComingsoon,
  BoxCard,
  ComingSoonContainer,
  LeftCoinStyled,
  LeftCoinImage,
};

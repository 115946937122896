import React from "react";
import {
  BoxFooter,
  FooterStyle,
  FooterStyleMobile,
  IconBlock,
  LeftFooter,
  ParagraphFooter,
  RightFooter,
  TextParagraph,
  TitleParagraph,
  LogoMobile,
  SectionFooterMobile,
  ContainerFooter,
} from "./FooterStyled";
import { Logo } from "../general/logo";
import { cronusLink, externalLink } from "../../utils/link";
import { Trans } from "@lingui/macro";

const Footer = () => {
  return (
    <ContainerFooter>
      <FooterStyle>
        <BoxFooter>
          <LeftFooter>
            <Logo />
            <IconBlock />
          </LeftFooter>
          <RightFooter>
            <ParagraphFooter>
              <TitleParagraph>
                <Trans>CRONUS TOKEN</Trans>
              </TitleParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={externalLink.defillama}
              >
                <Trans>DeFi Llama</Trans>
              </TextParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={externalLink.coinGecko}
              >
                <Trans> CoinGecko</Trans>
              </TextParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={externalLink.dexScreener}
              >
                <Trans>DEXScreen</Trans>
              </TextParagraph>
            </ParagraphFooter>
            <ParagraphFooter>
              <TitleParagraph>
                <Trans>BRIDGING</Trans>
              </TitleParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={externalLink.multichain}
              >
                <Trans>Multichain</Trans>
              </TextParagraph>
            </ParagraphFooter>
            <ParagraphFooter>
              <TitleParagraph>
                <Trans>BUSINESS</Trans>
              </TitleParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={externalLink.github}
              >
                <Trans>GitHub</Trans>
              </TextParagraph>
              <TextParagraph
                target="_blank"
                rel="noopener"
                href={cronusLink.docs}
              >
                <Trans>Docs</Trans>
              </TextParagraph>
            </ParagraphFooter>
          </RightFooter>
        </BoxFooter>
      </FooterStyle>
      <FooterStyleMobile>
        <SectionFooterMobile>
          <LogoMobile />
          <ParagraphFooter>
            <TitleParagraph>CRONUS TOKEN</TitleParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={externalLink.defillama}
            >
              DeFi Llama
            </TextParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={externalLink.coinGecko}
            >
              CoinGecko
            </TextParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={externalLink.dexScreener}
            >
              DEXScreen
            </TextParagraph>
          </ParagraphFooter>
        </SectionFooterMobile>
        <SectionFooterMobile>
          <ParagraphFooter>
            <TitleParagraph>
              <Trans>BRIDGING</Trans>
            </TitleParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={externalLink.multichain}
            >
              <Trans>Multichain</Trans>
            </TextParagraph>
          </ParagraphFooter>
          <ParagraphFooter>
            <TitleParagraph>
              <Trans>BUSINESS</Trans>
            </TitleParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={externalLink.github}
            >
              <Trans>GitHub</Trans>
            </TextParagraph>
            <TextParagraph
              target="_blank"
              rel="noopener"
              href={cronusLink.docs}
            >
              <Trans>Docs</Trans>
            </TextParagraph>
          </ParagraphFooter>
        </SectionFooterMobile>
      </FooterStyleMobile>
    </ContainerFooter>
  );
};

export default Footer;

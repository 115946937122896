import styled from "styled-components";
import FourT2 from "../../assets/partnerslogos/FourT2.png";
import Axelar from "../../assets/partnerslogos/Axelar.png";
import CelercBridge from "../../assets/partnerslogos/CelercBridge.png";
import Coslend from "../../assets/partnerslogos/Coslend.png";
import EvmosDomains from "../../assets/partnerslogos/EvmosDomains.png";
import Frax from "../../assets/partnerslogos/Frax.png";
import Maven11Capital from "../../assets/partnerslogos/Maven11Capital.png";
import Multichain from "../../assets/partnerslogos/Multichain.png";
import Nomad from "../../assets/partnerslogos/Nomad.png";
import { ReactComponent as Verilog } from "../../assets/partnerslogos/Verilog.svg";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const AuditedLogo = styled(Verilog)`
  max-width: 400px;
  margin-top: 0;
  padding: 0;
  @media (max-width: 920px) {
    max-width: 190px;
  }
`;

const DivContainerAudited = styled.div`
  display: flex;
  align-content: center;
  align-item: center;
  justify-content: center;
`;
const AuditedPartner = () => {
  return (
    <div style={{ height: "130px", display: "flex", alignContent: "center" }}>
      <AuditedLogo />
    </div>
  );
};
const ContainerSlide = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const CarouselParnersMobile = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        margin: 0,
      }}
    >
      <Swiper
        modules={[Autoplay]}
        autoplay={true}
        spaceBetween={0}
        loop={true}
        centeredSlides={true}
        slidesPerView={1}
        speed={5000}
        style={{
          height: "130px",
          width: "100%",
          margin: "0px",
        }}
      >
        {/*<SwiperSlide>
                <ImgContainer src={Maven11Capital}/>
        </SwiperSlide>*/}

        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={CelercBridge} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={Frax} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={Axelar} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={FourT2} />
          </ContainerSlide>
        </SwiperSlide>

        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={Coslend} height={"auto"} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={EvmosDomains} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={Multichain} />
          </ContainerSlide>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerSlide>
            <ImgContainer width={"100%"} src={Nomad} />
          </ContainerSlide>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const CarouselPartners = () => {
  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={true}
      spaceBetween={0}
      loop={true}
      slidesPerView={3}
      speed={5000}
      style={{
        height: "130px",
        width: "100%",
      }}
    >
      <SwiperSlide>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ImgContainer width={"100%"} src={CelercBridge} />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer width={"100%"} src={Frax} />
      </SwiperSlide>

      <SwiperSlide>
        <ImgContainer width={"100%"} src={Axelar} />
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer width={"100%"} src={FourT2} />
      </SwiperSlide>

      <SwiperSlide>
        <ImgContainer src={Coslend} width={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer src={EvmosDomains} width={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer src={Multichain} width={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <ImgContainer src={Nomad} width={"100%"} />
      </SwiperSlide>
    </Swiper>
  );
};
const DivCarouselStyled = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
const BackedByMobile = () => {
  return (
    <DivCarouselStyled>
      <ImgMave style={{ minWidth: "50px" }} src={Maven11Capital} />
    </DivCarouselStyled>
  );
};
const BackedBy = () => {
  return (
    <DivCarouselStyled>
      <ImgMave style={{ minWidth: "50px" }} src={Maven11Capital} />
    </DivCarouselStyled>
  );
};
const ImgMave = styled.img`
  max-height: 400px;
  @media (max-width: 920px) {
    width: 70%;
  }
`;
const ImgContainer = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 100%;
  @media (max-width: 920px) {
    width: 70%;
  }
`;

const TitleOurPartners = styled.p`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 175%;
  text-align: center;
  color: #b50028;
  padding: 0rem;
  @media (max-width: 768px) {
    font-size: 22px;
    font-height: 130%;
  }
  @media (min-width: 769px) and (max-width: 920px) {
    font-size: 28px;
  }
`;
const BoxOurPartners = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 920px) {
    display: none;
  }
`;
const BoxOurPartnersMobile = styled.div`
  display: none;
  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 2rem;
  }
`;
const SpanOurPartners = styled.span`
  background: linear-gradient(
    to bottom,
    #2c2f36 0%,
    #2c2f36 50%,
    #191b1f 50%,
    #191b1f 100%
  );
  color: white;
`;
const PhotoOurPartners = styled.div`
  display: flex;
  flex-direction: row;
  height: 130px;
  overflow-x: inherit;
`;

export {
  TitleOurPartners,
  BoxOurPartners,
  PhotoOurPartners,
  SpanOurPartners,
  CarouselPartners,
  AuditedPartner,
  BackedBy,
  BackedByMobile,
  BoxOurPartnersMobile,
  CarouselParnersMobile,
  DivContainerAudited,
};

import React from "react";
import { LogoCronus, LogoContainter } from "./LogoStyled";
const Logo = () => {
  return (
    <>
      <LogoContainter>
        <LogoCronus />
      </LogoContainter>
    </>
  );
};

export default Logo;

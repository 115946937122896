import styled from "styled-components";
import { ReactComponent as Pattern } from "../../assets/pattern.svg";

const BannerBox = styled.div`
  height: 453px;
  background-color: #b50028;
  border-radius: 0px;
  margin-bottom: 5rem;
  @media (max-width: 768px) {
    max-width: 100%;
    height: 315px;
  }
`;
const SecondCoin = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  right: 5rem;
  top: -5rem;
  position: absolute;
  @media (max-width: 920px) {
    display: none;
  }
`;
const FirstCoin = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: absolute;
  left: -10rem;
  top: 0rem;
  @media (max-width: 920px) {
    display: none;
  }
`;

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const PatternBanner = styled(Pattern)`
  width: 100%;
`;
const BannerSpan = styled.span`
  background: linear-gradient(
    to bottom,
    #b50028 0%,
    #b50028 50%,
    #e35e7d 50%,
    #e35e7d 100%
  );
`;
const BlockTextBanner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitleBanner = styled.p`
  max-width: 617px;
  height: 114px;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 150%;
  color: #ffffff;
  @media (max-width: 768px) {
    width: 100%;
    height: 54px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    color: #ffffff;
  }

  @media (min-width: 769px) and (max-width: 920px) {
    font-size: 28px;
  }
`;
const TextBanner = styled.p`
  max-width: 572px;
  height: 32px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 90%;
  color: #ffffff;
  @media (max-width: 768px) {
    width: 100%;
    height: 42px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
  }
`;
const ButtonBanner = styled.button`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  border: none;
  width: 220px;
  height: 68px;
  background: #ffffff;
  border-radius: 0px;
  color: #b50028;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  transition-duration: 150ms;
  &:hover {
    color: #585858;
  }
  @media (max-width: 768px) {
    width: 151px;
    height: 45px;
  }
`;

const HalfBanner = styled.div`
  display: flex;
  flex-direction: row;
  height: 50%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Half2Banner = styled.div`
  margin-top: -60px;
  display: flex;
  flex-direction: row;
  height: 50%;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  padding: 0 5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: self-start;
    padding: 0 1rem 0.5rem;
  }
`;
const TextButtonBanner = styled.p`
  height: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
`;

export {
  BannerBox,
  ButtonBanner,
  TextButtonBanner,
  HalfBanner,
  PatternBanner,
  BannerSpan,
  TitleBanner,
  TextBanner,
  BlockTextBanner,
  Half2Banner,
  BannerContainer,
  FirstCoin,
  SecondCoin,
};

import React, { useMemo } from "react";
import { HeaderMenu } from "../../components/header";
import { Presentation } from "../../components/presentation";
import { Description } from "../../components/description";
import { OurPartners } from "../../components/ourpartners";
import { Footer } from "../../components/footer";
import { Banner } from "../../components/banner";
import { FallbackImage, FirstSectionHome, VideoLoop } from "./HomeStyled";
import { Ourservices } from "../../components/ourservices";
import { Comingsoon } from "../../components/comingsoon";
import VideoStatua from "../../assets/videos/hero.webm";
import VideoStatuaHevc from "../../assets/videos/hero.mov";
import StatueFallback from "../../assets/hero.png";

const Home = () => {
  const navigator = window.navigator;
  const isSafari = useMemo(() => {
    const ua = navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(
      navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
    );
    return (
      ua.indexOf("safari") !== -1 &&
      !(ua.indexOf("chrome") !== -1) &&
      ua.indexOf("version/") !== -1
    );
  }, [navigator]);

  return (
    <>
      <HeaderMenu />
      <FirstSectionHome>
        <Presentation />
        <div style={{ minWidth: "300px" }}>
          {isSafari ? (
            <FallbackImage src={StatueFallback} />
          ) : (
            <VideoLoop
              width="100%"
              height="700px"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={VideoStatua} type="video/webm" />
            </VideoLoop>
          )}
        </div>
      </FirstSectionHome>
      <Description />
      <Ourservices />
      <Comingsoon />
      <Banner />
      <OurPartners />
      <Footer />
    </>
  );
};

export default Home;

import React from "react";
import { cronusLink } from "../../../utils/link";
import {
  AnalyticsIcon,
  ArrowRightIcon,
  BridgeIcon,
  ButtonCard,
  CardContainer,
  FarmIcon,
  LeftCard,
  LiquidityIcon,
  PatternRight,
  RightCard,
  StakeIcon,
  SwapIcon,
  TextAndButtonContainer,
  TextContainer,
  TitleContainer,
} from "./CardStyled";
import { CardComing } from "./interfaces";

const Card = ({ icon, textBox, title }: CardComing) => {
  const getIcon = () => {
    switch (icon) {
      case "swap":
        return <SwapIcon />;
      case "liquidity":
        return <LiquidityIcon />;
      case "farm":
        return <FarmIcon />;
      case "stake":
        return <StakeIcon />;
      case "bridge":
        return <BridgeIcon />;
      case "analytics":
        return <AnalyticsIcon />;
    }
  };

  const getLink = () => {
    switch (icon) {
      case "swap":
        return cronusLink.swap;
      case "liquidity":
        return cronusLink.liquidity;
      case "farm":
        return cronusLink.farms;
      case "stake":
        return cronusLink.stake;
      case "bridge":
        return cronusLink.bridge;
      case "analytics":
        return cronusLink.analytics;
    }
  };
  return (
    <a target="_blank" href={`${getLink()}`}>
      <CardContainer>
        <TextAndButtonContainer>
          <LeftCard>
            {getIcon()}
            <TitleContainer>{title}</TitleContainer>
            <TextContainer>{textBox}</TextContainer>
          </LeftCard>
          <RightCard>
            <PatternRight />
            <ButtonCard>
              <ArrowRightIcon />
            </ButtonCard>
          </RightCard>
        </TextAndButtonContainer>
      </CardContainer>
    </a>
  );
};

export default Card;

import React from "react";
import {
  TitleOurservices,
  SpanOurservices,
  ContainerCard,
  GroupCard,
  ContainerOnlyMobile,
  ContainerDesktoOurServices,
  BoltImage,
} from "./OurservicesStyled";

import { Card } from "./card";
import { Swiper, SwiperSlide } from "swiper/react";
import BoltDesktop from "../../assets/boltDesktop.png";

const Ourservices = () => {
  return (
    <>
      <ContainerDesktoOurServices>
        <BoltImage style={{ minWidth: "50px" }} src={BoltDesktop} />
        <TitleOurservices>
          Our <SpanOurservices> Services</SpanOurservices>
        </TitleOurservices>

        <ContainerCard>
          <GroupCard>
            <Card
              icon={"analytics"}
              title={"Perpetuals"}
              textBox={
                "Long and short the market through high leverage powered by our dark oracle stack"
              }
            />
            <Card
              icon={"farm"}
              title={"Real Yield"}
              textBox={
                "Deposit in our pools and access real yield granted by liquidations and fees"
              }
            />
            <Card
              icon={"liquidity"}
              title={"Social Trading"}
              textBox={
                "Trade with your friends, chat on the trollbox and climb our leaderboard for extra perks"
              }
            />
            {/*
            <Card
              icon={"stake"}
              title={"Stake"}
              textBox={
                "Get a share of protocol fees by staking Cronus native token, CRN to earn stablecoin rewards."
              }
            />
            <Card
              icon={"bridge"}
              title={"TetraSwap"}
              textBox={
                "Swap between bridged & native stables on Evmos, with low slippage."
              }
            />
            <Card
              icon={"analytics"}
              title={"Analytics"}
              textBox={
                "Access crypto data sets and control the market through a on-chain analytics suite powered by Cronus.                ."
              }
            />
            */}
          </GroupCard>
        </ContainerCard>
      </ContainerDesktoOurServices>
      <ContainerOnlyMobile>
        <TitleOurservices>
          Our <SpanOurservices>Services</SpanOurservices>
        </TitleOurservices>
        <Swiper
          slidesPerView={1.4}
          spaceBetween={10}
          style={{
            width: "100%",
            height: "300px",
            borderRadius: "0",
          }}
        >
          <SwiperSlide>
            <Card
              icon={"analytics"}
              title={"Perpetuals"}
              textBox={
                "Long and short with high leverage powered by our dark oracle stack"
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              icon={"farm"}
              title={"Real Yield"}
              textBox={
                "Deposit in our pools and access real yield granted by liquidations and fees"
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              icon={"liquidity"}
              title={"Social Trading"}
              textBox={
                "Trade against your friends, challenge them in our trollbox and get to the top of our leaderboard for further benefits"
              }
            />
          </SwiperSlide>
          {/*
          <SwiperSlide>
            <Card
              icon={"stake"}
              title={"Stake"}
              textBox={
                "Mauris elementum elit ut est pretium, consectetur convallis ex elementum."
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              icon={"bridge"}
              title={"TetraSwap"}
              textBox={
                "Mauris elementum elit ut est pretium, consectetur convallis ex elementum."
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              icon={"analytics"}
              title={"Analytics"}
              textBox={
                "Mauris elementum elit ut est pretium, consectetur convallis ex elementum."
              }
            />
          </SwiperSlide> */}
        </Swiper>
      </ContainerOnlyMobile>
    </>
  );
};
export default Ourservices;

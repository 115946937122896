import styled from "styled-components";

import { ReactComponent as LogoCr } from "../../../assets/logo.svg";

const LogoContainter = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    max-width: 70%;
    justify-content: start;
  }
`;

const LogoCronus = styled(LogoCr)`
  @media (max-width: 768px) {
    width: auto;
  }
`;

export { LogoContainter, LogoCronus };

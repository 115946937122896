// eslint-disable-next-line no-restricted-globals
const URL: string = location.origin.endsWith("cronusfinance.xyz")
  ? "cronusfinance.xyz"
  : "cronusfinancexyz.com";

const cronusLink = {
  swap: `https://app.${URL}/#/swap`,
  liquidity: `https://app.${URL}/#/liquidity`,
  farms: `https://app.${URL}/#/farms`,
  bridge: `https://app.${URL}/#/bridge`,
  launch: `https://trading.${URL}/`,
  docs: `https://docs.${URL}`, // `https://docs.${URL}`,
  analytics: `https://analytics.${URL}`,
  stake: `https://stake.${URL}`,
};

const socialLink = {
  discord: "https://discord.gg/zs6kAZzPdF",
  twitter: "https://twitter.com/cronusfinance",
  medium: "https://medium.com/@cronusfinance",
};

const externalLink = {
  defillama: "https://defillama.com/protocol/cronus-finance",
  coinGecko: "https://www.coingecko.com/en/coins/cronus-finance",
  dexScreener: "https://dexscreener.com/evmos/cronusfinance",
  cBridge: "https://cbridge.celer.network/#/transfer",
  multichain: "https://app.multichain.org/",
  github: "https://github.com/cronus-finance/",
};

const goto = (url: string) => {
  window.open(url);
};
export { cronusLink, externalLink, socialLink, goto };

import styled from "styled-components";
/*import { ReactComponent as Bolt } from "../../assets/Bolt.svg";
const BoltIcon = styled(Bolt)`
  display: block;
  position: absolute;
  width: 500px;
  height: 500px;

  @media (max-width: 768px) {
    display: none;
  }
`;*/
const TitleOurservices = styled.p`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  color: #b50028;

  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 769px) and (max-width: 920px) {
    font-size: 28px;
  }
`;

const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ContainerDesktoOurServices = styled.div`
  margin-bottom: 4rem;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;
const BoltImage = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: absolute;
  right: -20rem;
  top: -15rem;
  z-index: -1;
  max-width: 834px;
  @media (max-width: 920px) {
    display: none;
  }
`;

const GroupCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-row-gap: 2rem;
`;

const SpanOurservices = styled.span`
  background: linear-gradient(
    to bottom,
    #2c2f36 0%,
    #2c2f36 50%,
    #191b1f 50%,
    #191b1f 100%
  );
  color: white;
`;

const ContainerOnlyMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    text-align: left;
    display: flex;
    flex-direction: column;

    width: auto;
    height: auto;
    overflow: visible;
  }
`;

export {
  TitleOurservices,
  SpanOurservices,
  ContainerCard,
  GroupCard,
  ContainerOnlyMobile,
  ContainerDesktoOurServices,
  BoltImage,
};
